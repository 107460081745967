var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h6',[_vm._v("Data Izin Usaha Kawasan")]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},_vm._l(([
          { key: 'LAYANAN', value: _vm.ijin?.urailayan },
          { key: 'TGL. AWAL', value: _vm.ijin?.tglawal },
          { key: 'TGL. AKHIR', value: _vm.ijin?.tglakhir },
        ]),function(item,i){return _c('div',{key:i,staticClass:"mb-3"},[_c('p',{staticClass:"text-uppercase fw-medium fs-14 mb-2"},[_vm._v(_vm._s(item.key)+" :")]),_c('p',{staticClass:"mb-0 fs-14 fw-light"},[_vm._v(_vm._s(item.value))])])}),0),_c('div',{staticClass:"col-md-6"},_vm._l(([
          { key: 'NO. SURAT PERMOHONAN', value: _vm.ijin?.no_surat_mohon },
          { key: 'TGL. DAFTAR', value: _vm.ijin?.tgl_daftar },
        ]),function(item,i){return _c('div',{key:i,staticClass:"mb-3"},[_c('p',{staticClass:"text-uppercase fw-medium fs-14 mb-2"},[_vm._v(_vm._s(item.key)+" :")]),_c('p',{staticClass:"mb-0 fs-14 fw-light"},[_vm._v(_vm._s(item.value))])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }