<template>
  <div>
    <h6>Data Izin Usaha Kawasan</h6>
    <br />
    <div class="row">
      <div class="col-md-6">
        <div
          v-for="(item, i) in [
            { key: 'LAYANAN', value: ijin?.urailayan },
            { key: 'TGL. AWAL', value: ijin?.tglawal },
            { key: 'TGL. AKHIR', value: ijin?.tglakhir },
          ]"
          :key="i"
          class="mb-3"
        >
          <p class="text-uppercase fw-medium fs-14 mb-2">{{ item.key }} :</p>
          <p class="mb-0 fs-14 fw-light">{{ item.value }}</p>
        </div>
      </div>
      <div class="col-md-6">
        <div
          v-for="(item, i) in [
            { key: 'NO. SURAT PERMOHONAN', value: ijin?.no_surat_mohon },
            { key: 'TGL. DAFTAR', value: ijin?.tgl_daftar },
          ]"
          :key="i"
          class="mb-3"
        >
          <p class="text-uppercase fw-medium fs-14 mb-2">{{ item.key }} :</p>
          <p class="mb-0 fs-14 fw-light">{{ item.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataIzinUsahaKawasan",
  computed: {
    ijin() {
      return this.$store.state.realisasi.report.ijin;
    },
  },
};
</script>
